import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import Start from '../views/Start.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/wirkstoffe',
    name: 'Wirkstoffe',
    component: () => import('../views/Wirkstoffe.vue'),
    meta: {
      breadCrumb () {
        return [
          {
            text: 'Home',
            to: 'Home'
          }
        ]
      }
    }
  },
  {
    path: '/wirkstoffe/:wirkstoff?',
    name: 'Wirkstoff',
    component: () => import('../views/Wirkstoff.vue'),
    meta: {
      breadCrumb () {
        return [
          {
            text: 'Home',
            to: 'Home'
          },
          {
            text: 'Wirkstoffe',
            to: 'Wirkstoffe'
          }
        ]
      }
    }
  },
  {
    path: '/themen',
    name: 'Themen',
    component: () => import('../views/Themen.vue'),
    meta: {
      breadCrumb () {
        return [
          {
            text: 'Home',
            to: 'Home'
          }
        ]
      }
    }
  },
  {
    path: '/themen/:thema?',
    name: 'Thema',
    component: () => import('../views/Thema.vue'),
    meta: {
      breadCrumb () {
        return [
          {
            text: 'Home',
            to: 'Home'
          },
          {
            text: 'Themen',
            to: 'Themen'
          }
        ]
      }
    }
  },
  {
    path: '/suche',
    name: 'Suche',
    component: () => import('../views/Suche.vue'),
    meta: {
      breadCrumb () {
        return [
          {
            text: 'Home',
            to: 'Home'
          }
        ]
      }
    }
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: () => import('../views/Quiz.vue')
  },
  {
    path: '/quiz/:thema',
    name: 'QuizDetail',
    component: () => import('../views/QuizDetail.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('../views/Impressum.vue')
  },
  {
    path: '/pmu300score',
    name: 'Score',
    component: () => import('../views/Score.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: Start
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (from.name) {
    document.body.classList.remove(from.name.toLowerCase() + '-view')
    if (from.name === 'Wirkstoff') {
      store.dispatch('logRoute', from)
    }
  }

  document.body.classList.add(to.name.toLowerCase() + '-view')

  next()
})

export default router
