export default {
  status: 'loading',
  wirkstoffe: [],
  score: [],
  themen: [],
  suche: {
    results: [],
    status: 'idle'
  },
  history: {
    routes: []
  }
}
