import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import store from './store/index'
import 'babel-polyfill'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import de from 'vee-validate/dist/locale/de.json'
import * as rules from 'vee-validate/dist/rules'
import SidebarOverview from './components/SidebarOverview-Page'
import VueMatomo from 'vue-matomo'

/* Import vue select */
import VueSelect from 'vue-select'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faUserSecret, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { faStarHalf, faStar } from '@fortawesome/free-regular-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faUserSecret, faStarHalf, faStar, faCircleInfo)

Vue.config.productionTip = false

// global config for XHR requests
const qs = require('qs')
if (process.env.NODE_ENV === 'development') {
  Axios.defaults.baseURL = 'http://localhost/pmu300/api/'
} else {
  Axios.defaults.baseURL = '/api/'
}
Axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'

// Axios.interceptors.request.use(config => {
//   const params = new URLSearchParams();
//   Object.keys(config.data).forEach(key => {
//     params.append(key, config.data[key]);
//   });
//   config.data = params
//   return config;
// });

Axios.interceptors.request.use(config => {
  config.data = qs.stringify(config.data)
  return config
})

UIkit.use(Icons)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

// VeeValidate
// Add a rule.

// extend('email', {
//   ...email,
//   message: 'This field is required'
// })

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    }
  },
  computesRequired: true,
  message: 'Bitte füllen Sie dieses Feld aus'
})

localize('de', de)

// Install matomo for tracking
Vue.use(VueMatomo, {
  host: 'https://onlineted.matomo.cloud/',
  siteId: 3,
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  trackerFileName: 'piwik',
  debug: true
})

// Install components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('VueSelect', VueSelect)
Vue.component('SidebarOverview', SidebarOverview)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
