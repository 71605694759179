import { render, staticRenderFns } from "./SidebarOverview-Page.vue?vue&type=template&id=aca32c7a&scoped=true&"
import script from "./SidebarOverview-Page.vue?vue&type=script&lang=js&"
export * from "./SidebarOverview-Page.vue?vue&type=script&lang=js&"
import style0 from "./SidebarOverview-Page.vue?vue&type=style&index=0&id=aca32c7a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aca32c7a",
  null
  
)

export default component.exports