<template>
  <div class="sidebar uk-container uk-background-secondary uk-height-1-1">
    <div uk-sticky="offset: 110; media: @m; end: true">
      <section class="">
        <div>
          <ul class="uk-breadcrumb">
            <li v-for="(item, i) in $route.meta.breadCrumb()" :key="i" class="uk-margin-small-bottom">
              <router-link :to="{name: item.to}" class="uk-text-underline no-router-active" exact>{{item.text}}</router-link>
            </li>
            <li class=""><span>{{$route.name}}</span></li>
          </ul>
        </div>
        <h2>Trends</h2>
        <div>
          <h4 class="uk-text-uppercase">Zuletzt aufgerufen</h4>
          <ul class="blank" v-if="history.length > 0">
            <li v-for="(route, i) in history" :key="i" class="uk-margin-bottom"><router-link :to="route.fullPath" class="no-router-active">{{firstParam(route).val}}</router-link></li>
          </ul>
          <div v-else class="uk-text-italic uk-text-small">keine Einträge</div>
        </div>
      </section>
      <hr class="uk-margin-medium" />

      <section class="" v-if="$route.name === 'Wirkstoff'">
        <h4 class="uk-text-uppercase">Diese Wirkstoffe könnten dich interessieren</h4>
        <ul class="blank">
          <li v-for="(v, i) in w.others" :key="i" class="uk-margin-bottom">
            <router-link :to="{ name: 'Wirkstoff', params: { wirkstoff: v.Wirkstoff }}">{{v.Wirkstoff}}</router-link>
          </li>
        </ul>
        <hr class="uk-margin-medium" />
      </section>

      <section class="" v-if="$route.name !== 'Wirkstoff' && $route.name !== 'Themen'">
        <!-- <h4 class="uk-text-uppercase uk-text-light">PMU300 <span class="uk-text-bold">Score</span></h4>
        <ul class="blank">
          <li v-for="(w, i) in score" :key="i" class="uk-margin-bottom">
            <router-link :to="{ name: 'Wirkstoff', params: { wirkstoff: w.Wirkstoff }}">Platz {{i + 1}}: {{w.Wirkstoff}}</router-link>
          </li>
        </ul> -->

        <h4 class="uk-text-uppercase">Häufig gesucht</h4>
        <ul class="blank">
          <li v-for="(w, i) in mostviewed" :key="i" class="uk-margin-bottom">
            <router-link :to="{ name: 'Wirkstoff', params: { wirkstoff: w.Wirkstoff }}">Platz {{i + 1}}: {{w.Wirkstoff}}</router-link>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Axios from 'axios'

export default {
  name: 'SidebarOverview',
  data () {
    return {
      mostviewed: []
    }
  },
  components: {},
  props: ['location', 'w'],
  computed: {
    ...mapState({
      history: state => state.history.routes
    }),
    ...mapState({
      score: state => state.score
    })
  },
  mounted: function () {
    Axios
      .get('wirkstoffe/mostviewed')
      .then((response) => {
        this.mostviewed = response.data
      })
      .catch((error) => {
        console.log('Error on getting most viewed wirkstoffe', error)
      })
  },
  methods: {
    firstParam: (route) => {
      let o = {}
      if (Object.keys(route.params).length > 0) {
        // eslint-disable-next-line
        for (const prop in route.params) {
          o = { key: prop, val: route.params[prop] }
        }
      } else if (route.name === 'Suche') {
        o = { val: 'Suche: "' + route.query.t + '"' }
      } else {
        o = { val: route.name }
      }
      return o
    }
  }
}
</script>

<style lang="scss" scoped>
.rating {
  text-align: center;
  margin-top: 120px;
  position: relative;
  width: 50%;
  float: left;
}

.hidden {
  opacity: 0;
}

</style>
