import Vue from 'vue'
import Vuex from 'vuex'
import State from './state'
import Actions from './actions'
import Mutations from './mutations'

Vue.use(Vuex)

const state = State
const actions = Actions
const mutations = Mutations
const getters = {}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
  }
})
