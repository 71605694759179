<template>
  <div class="loader-wrap">
    <div class="lds-ripple"><div></div><div></div></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoaderIcon',
  data () {
    return {}
  },
  computed: {
    ...mapState({
      appState: state => state.status
    })
  },
  mounted: function () {}
}
</script>

<style lang="scss" scoped>
  $--p-1: #d4aee0;
  $--p-2: #8975b4;
  $--p-3: #64518a;
  $--p-4: #565190;

  $--b-1: #44abac;
  $--b-2: #2ca7d8;
  $--b-3: #1482ce;
  $--b-4: #05597c;

  $--g-1: #b2dd57;
  $--g-2: #57c443;
  $--g-3: #05b853;
  $--g-4: #19962e;

  $--y-1: #fdc82e;
  $--y-2: #fd9c2e;
  $--y-3: #d5385a;
  $--y-4: #911750;

  $--s-1: #d9d9d9;
  $--s-2: #9e9e9e;
  $--s-3: #666666;
  $--s-4: #2b2b2b;

  $colors: (
    $--p-1,
    $--p-2,
    $--p-3,
    $--p-4,
    $--b-1,
    $--b-2,
    $--b-3,
    $--b-4,
    $--g-1,
    $--g-2,
    $--g-3,
    $--g-4,
    $--y-1,
    $--y-2,
    $--y-3,
    $--y-4,
  );

  h2 {
    display: block;
    color: white;
    font-weight: 100;
    //margin-right: 2rem;
    text-align: center;
  }

  .loader-wrap{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(100, 100, 100, .85);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
</style>
