// Manipulate from the current state.
export default {
  UPDATE_WIRKSTOFFE (state, wirkstoffe) {
    state.wirkstoffe = wirkstoffe
  },
  UPDATE_SCORE (state, score) {
    state.score = score
  },
  UPDATE_THEMEN (state, themen) {
    state.themen = themen
  },
  SET_ASSETS (state, data) {
    state.assets = data.assets
    state.assetsGrouped = data.assetsGrouped
  },
  SET_APP_STATUS (state, status) {
    state.status = status
  },
  SET_SEARCH_RESULT (state, result) {
    state.suche.results = result
  },
  SET_SEARCH_STATUS (state, status) {
    state.suche.status = status
  },
  LOG_ROUTE (state, route) {
    state.history.routes.push(route)
    if (state.history.routes.length > 5) {
      state.history.routes.shift()
    }
  },
  UPDATE_VIEW_COUNT (state, { id, views }) {
    const newArr = state.wirkstoffe.map(obj => {
      if (obj.id === id) {
        return { ...obj, Views: views }
      }
      return obj
    })
    state.wirkstoffe = newArr
  }
}
